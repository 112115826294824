import React from "react";
import Canvas from "./components/Canvas/Canvas";

import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="browser w-screen h-screen">
        <div className="h-full flex justify-center items-center">Better experience on mobile 👟</div>
      </div>
      <div className="mobile w-screen h-screen">
        <div
          id="loader-wrapper"
          className="absolute inset-y-1/2 lds-dual-ring"
          alt="loading"
        ></div>

        <Canvas />
      </div>
    </div>
  );
}

export default App;
