export const getMobileOperatingSystem = () => {
  let userAgent = navigator.userAgent || navigator.vendor;

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "iOS";
  }

  return "unknown";
};

export const startExternalVideo = (facingMode, videoObjects) => {
  cleanupVideoObjects(videoObjects);
  // create video element
  var video = document.createElement("video");
  video.muted = true;
  video.loop = true;
  video.controls = true;
  video.setAttribute("playsinline", "playsinline");
  video.style.width = "100%";
  video.style.height = "100%";

  // put it somewhere in the DOM
  var videoContainer = document.createElement("div");
  videoContainer.appendChild(video);
  videoContainer.style.width = "1px";
  videoContainer.style.height = "1px";
  videoContainer.style.position = "absolute";
  videoContainer.style.top = "0px";
  videoContainer.style.left = "0px";
  videoContainer.style["z-index"] = "-1";
  document.body.appendChild(videoContainer);

  videoObjects.videoContainer = videoContainer;
  videoObjects.video = video;

  navigator.mediaDevices
    .getUserMedia({ video: { facingMode } })
    .then(function (stream) {
      try {
        video.srcObject = stream;
      } catch (error) {
        video.src = URL.createObjectURL(stream);
      }
      setTimeout(function () {
        video.play();
      }, 50);
    })
    .catch(function (error) {
      console.log("error in video play:", error);
    });

    return { video, facingMode }
 
};

export const cleanupVideoObjects = (videoObjects) => {
  if (videoObjects.length > 0) {
    videoObjects.videoContainer.parentNode.removeChild(
      videoObjects.videoContainer
    );
    videoObjects.videoContainer = null;
    if (videoObjects.video.srcObject) {
      //getUserMedia starts a stream, all tracks on all streams need to be stoppedbefore calling getUserMedia again
      videoObjects.video.srcObject.getTracks().forEach((track) => track.stop());
    }
    videoObjects.video.pause();
    videoObjects = {};
  }
};
