export const saveToDevice = (canvas) => {
  let link = document.createElement("a");
  link.href = canvas.toDataURL();
  link.download = 'Siam Discovery Sneakers Killer.png';
  link.click();
}

export const share = async (canvas) => {
  canvas.toBlob(async (blob) =>  {
    const files = [new File([blob], 'image.png', { type: blob.type })]
    const shareData = {
      text: "Siam Discovery Sneakers Killer",
      title: "Siam Discovery Sneakers Killer",
      files: files,
    };
    try {
      await navigator.share(shareData)
    } catch (err) {
      alert("กรุณาเล่นผ่านเบราว์เซอร์ Chrome เพื่อบันทึกและแชร์รูปภาพ");
      throw err
    }
  })
};