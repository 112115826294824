import React from "react";
import { share } from "../../utilities/socialShare";
import "./PreviewScreenshot.css";

function PreviewScreenshot({ canvas, discard }) {
  return (
    <>
      {canvas !== undefined && (
        <div className="preview absolute w-screen h-screen z-10">
          <div className="flex absolute p-6 justify-between w-screen z-10">
            <div
              className="grid justify-center justify-items-center"
              onClick={() => discard()}
            >
              <img className="left-0" src="assets/close-icon.svg" alt="close" />
              <div className="text-white">Close</div>
            </div>
            <div
              className="grid justify-center justify-items-center"
              onClick={async () => await share(canvas)}
            >
              <img
                className="right-0"
                src="assets/share-icon.svg"
                alt="share"
              />
              <div className="text-white">Save & Share</div>
            </div>
          </div>
          <img src={canvas.toDataURL("image/png")} />
        </div>
      )}
    </>
  );
}

export default PreviewScreenshot;
