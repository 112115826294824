import { useEffect } from "react";
import * as ReactDOM from "react-dom/client";
import {
  getMobileOperatingSystem,
  startExternalVideo,
} from "../../utilities/canvasUtil";
import Carousel from "../Carousel/Carousel";
import { captureScreen } from "../../utilities/screenCapture";
import PreviewScreenshot from "../PreviewScreenshot/PreviewScreenshot";

function Canvas() {
  const handleDiscard = () => {
    // const preview = ReactDOM.createRoot(document.getElementById("preview"));
    // preview.unmount();
    window.location.reload()
  };

  const handleCapScreen = async () => {
    let canvas = await captureScreen();

    const preview = ReactDOM.createRoot(document.getElementById("preview"));

    preview.render(
      <PreviewScreenshot canvas={canvas} discard={handleDiscard} />
    );
  };

  let deepAR = null;
  let videoObjects = {};
  let canvasHeight = window.innerHeight;
  let canvasWidth = window.innerWidth;

  if (window.innerWidth > window.innerHeight) {
    canvasWidth = Math.floor(window.innerHeight * 0.66);
  }

  const iosDetected = getMobileOperatingSystem() === "iOS";

  const switchModel = (effect) => {
    deepAR.switchEffect(0, "mask", effect);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    deepAR = DeepAR({
      canvasWidth: canvasWidth,
      canvasHeight: canvasHeight,
      licenseKey: process.env.REACT_APP_LICENSE_KEY,
      canvas: document.getElementById("deepar-canvas"),
      numberOfFaces: 1,
      footTrackingConfig: {
        poseEstimationWasm: "lib/libxzimgPoseEstimation.wasm",
        detector: iosDetected
          ? "lib/foot-detector-ios.bin"
          : "lib/foot-detector-android.bin",
        tracker: iosDetected
          ? "lib/foot-tracker-ios.bin"
          : "lib/foot-tracker-android.bin",
        obj: "lib/foot-model.obj",
      },
      onInitialize: function () {
        let { video, facingMode } = startExternalVideo(
          "environment",
          videoObjects
        );
        deepAR.setVideoElement(video, facingMode === "user");
        deepAR.loadFootTrackingModel(function () {
          deepAR.switchEffect(0, "mask", "effects/02_Poorboy", function () {
            const loaderWrapper = document.getElementById("loader-wrapper");
            loaderWrapper.style.display = "none";
            const arcanvas = document.getElementById("ar-canvas");
            arcanvas.style.display = "flex";
            setInterval(() => {
              const suggestion = document.getElementById("suggestion");
              suggestion.style.display = "none";
            }, 3000);
          });
        });
      },
    });
  }, []);

  return (
    <div
      id="ar-canvas"
      className="w-screen h-screen"
      style={{ display: "none" }}
    >
      <div id="suggestion" className="flex absolute w-full h-full">
        <div className="m-auto rounded-xl text-white bg-neutral-700 opacity-60 px-5 py-2">
          <div className="text-2xl">Slide to change model</div>
          <div>เลื่อนเพื่อเปลี่ยนแบบรองเท้า</div>
        </div>
      </div>

      <div id="preview"></div>

      <canvas className="w-screen h-screen deepar" id="deepar-canvas"></canvas>

      <Carousel handleSelect={switchModel} snapshot={handleCapScreen} />
    </div>
  );
}

export default Canvas;
