import React from "react";
import Slider from "react-slick";
import "./Carousel.css"

function Carousel({ handleSelect, snapshot }) {
  const models = [
    { img: "02_Poorboy.png", effect: "02_Poorboy" },
    { img: "04_Black.png", effect: "04_Black" },
    { img: "05_White.png", effect: "05_White" },
    { img: "06_Orange.png", effect: "06_Orange" },
    { img: "07_Yellow.png", effect: "07_Yellow" },
    { img: "08_Pink.png", effect: "08_Pink" },
    { img: "03_Poorboy.png", effect: "03_Poorboy" },
    { img: "09_OutCast_Black.png", effect: "09_OutCast_Black" },
    { img: "10_OutCast_Red.png", effect: "10_OutCast_Red" },
    { img: "11_OutCast_Orange.png", effect: "11_OutCast_Orange" },
    { img: "12_OutCast_White.png", effect: "12_OutCast_White" }
  ];

  const settings = {
    centerMode: true,
    centerPadding: "10px",
    slidesToShow: 3,
    arrows: false,
    beforeChange: (lastIndex, currIndex) => {
      handleSelect(`effects/${models[currIndex].effect}`)
    }
  };

  return (
    <div className="fixed bottom-10 w-full h-32 px-8">
      <Slider {...settings}>
        {models.map((model) => (
          <img
            key={model.img}
            src={`thumbs/${model.img}`}
            onClick={snapshot}
          />
        ))}
      </Slider>
      <div className="text-white">Take a photo</div>
    </div>
  );
}

export default Carousel;
